import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  inwardflow: [],
  viewInwardFlow: [],
  getinwardbyid:[]
};

const slice = createSlice({
  name: 'inwardflow',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

     // GET Inward
    getInwardFlowSucess(state, action) {
        state.isLoading = false;
        state.inwardflow = action.payload;
    },

       
    // View Inward
    ViewInwardFlowSucess(state, action) {
      state.isLoading = false;
      state.viewInwardFlow = action.payload;
  },
  getinwardbyidflowsuccess(state,action){
    state.isLoading = false;
    state.getinwardbyid = action.payload;
  }
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getInwardFlow() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/inwardFlow/getInwardFlow`);
            dispatch(slice.actions.getInwardFlowSucess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function ViewInwardFlow(inwardFlowId) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
          const response = await axios.get(`/inwardFlow/viewInwardFlow/${inwardFlowId}`);
          dispatch(slice.actions.ViewInwardFlowSucess(response.data));
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error));
      }
  };
}

// ----------------------------------------------------------------------

export function getinwardflowbyId(inwardFlowId) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
          const response = await axios.get(`/inwardFlow/getinwardflowbyID/${inwardFlowId}`);
          dispatch(slice.actions.getinwardbyidflowsuccess(response.data));
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error));
      }
  };
}
