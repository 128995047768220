
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    totalInvoices: [],
    totalProformaInvoices:[],
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },


        // GET Total Invoices
        getTotalInvoices(state, action) {
            state.isLoading = false;
            state.totalInvoices = action.payload;
        },

        // GET Proforma Invoices
        getProformaInvoice(state, action) {
            state.isLoading = false;
            state.totalProformaInvoices = action.payload;
        },
       
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getTotalInvoiceList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/dashboard/getTotalInvoices`);
            dispatch(slice.actions.getTotalInvoices(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function getProformaInvoice() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/dashboard/gettotalProformaInvoice`);
            dispatch(slice.actions.getProformaInvoice(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}