import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    manufacturers: [],
    manufacturer: null,
    manufacturerBasedOnId:[],
};

const slice = createSlice({
    name: 'manufacturers',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CATEGORIES
        getManufacturersSuccess(state, action) {
            state.isLoading = false;
            state.manufacturers = action.payload;
        },

        // GET CATEGORY
        getManufacturerSuccess(state, action) {
            state.isLoading = false;
            state.manufacturer = action.payload;
        },
        getManufacturersBasedOnIdSuccess(state, action) {
            state.isLoading = false;
            state.manufacturerBasedOnId = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getManufacturers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/manufacturers/');
            dispatch(slice.actions.getManufacturersSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function getManufacturersBasedOnId(manufacturerId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoiceManagement/getManufacturers/${manufacturerId}`);
            dispatch(slice.actions.getManufacturersBasedOnIdSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}