import duotone from 'icons/duotone';
export const navigations = [{
  type: 'label',
  label: 'Dashboard'
},
{
  name: 'Dashboard',
  path: '/dashboard',
  icon: duotone.Dashboard
}, 
{
  name: 'Section Management',
  path: '/dashboard/section/section-list',
  icon: duotone.Pages
}, 
{
  name: 'Predefined Length Management',
  path: '/dashboard/predefinedlength/predefinedlength-list',
  icon: duotone.DiagramProject
},
{
  name: 'Inventories',
  path: '/dashboard/inventories/inventorieslist',
  icon: duotone.LayerGroup
},
{
  name: 'PO Management',
  path: '/dashboard/request/request-list',
  icon: duotone.ArrowLeftToLine
},
{
  name: 'Proforma Invoice Management',
  path: '/dashboard/proformaInvoice/proforma-list',
  icon: duotone.Invoice
},
{
  name: 'Invoice Management',
  path: '/dashboard/invoiceManagementList/invoice-list',
  icon: duotone.Pages
},
{
  name: 'Outward Flow Management',
  path: '/dashboard/outwardflow/outwardflow-list',
  icon: duotone.Login
},
{
  name: 'Inward Flow Management',
  path: '/dashboard/inwardflow/inwardflow-list',
  icon: duotone.Logout
},
{
  name: 'Vendor Management',
  path: '/dashboard/vendor/vendor-list',
  icon: duotone.UserProfile
}, 
{
  name: 'Users Management',
  path: '/dashboard/users/userlist',
  icon: duotone.UserList
},
{
  name: 'Roles Management',
  path: '/dashboard/roles/rolelist',
  icon: duotone.UserList
}
];