
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  roles: [],
  role: null,
  roleData: [],
  roleDept: [],
  allRoles: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATION CATEGORIES
    getRolebyIdSuccess(state, action) {
      state.isLoading = false;
      state.role = action.payload;
    },

    // GET ORGANIZATION CATEGORY
    getRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
    // GET ORGANIZATION CATEGORY
    getRoleDataSuccess(state, action) {
      state.isLoading = false;
      state.roleData = action.payload;
    },
    // GET ORGANIZATION CATEGORY
    getRoleDeptSuccess(state, action) {
      state.isLoading = false;
      state.roleDept = action.payload;
    },
    getAllRoleSuccesss(state, action) {
      state.isLoading = false;
      state.allRoles = action.payload;
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/roles');
      dispatch(slice.actions.getRoleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRole(roleId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/roles/getRoleById/${roleId}`);
      dispatch(slice.actions.getRolebyIdSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRoledata() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/roles/rolesdata`);
      dispatch(slice.actions.getRoleDataSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRolebyDeptdata(departmentId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/roles/getRoleByDeptId/${departmentId}`);
      dispatch(slice.actions.getRoleDeptSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getAllRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/roles/getRoles`);
      dispatch(slice.actions.getAllRoleSuccesss(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
