import React from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { JWTAuthProvider } from "contexts/JWTAuth";
import SettingsProvider from "contexts/settingsContext";
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';


import App from "./App";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./__fakeData__";

import { AuthProvider } from "auth/JwtContext";

// redux
import { store, persistor } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode>
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </PersistGate>
  </ReduxProvider>
</React.StrictMode >);