
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  proformarequests:[],
    viewproformarequests:[],
    proformabyId:[]
};

const slice = createSlice({
  name: 'proformaRequest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET requests
    getRequestSucess(state, action) {
      state.isLoading = false;
      state.proformarequests = action.payload;
    },

      // View requests
      ViewRequestSucess(state, action) {
        state.isLoading = false;
        state.viewproformarequests = action.payload;
      },
      // View requests
      getRequestByIdSucess(state, action) {
        state.isLoading = false;
        state.proformabyId = action.payload;
      },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getProformaReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/proforma_requests/');
      dispatch(slice.actions.getRequestSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ViewProformaRequests(proformaId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/proforma_requests/getProformaReqDetailsbyId/${proformaId}`);
      dispatch(slice.actions.ViewRequestSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProformaReqbyId(proformaId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/proforma_requests/getproformareqbyID/${proformaId}`);
      dispatch(slice.actions.getRequestByIdSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}