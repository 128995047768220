
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    invoiceList: [],
    invoiceProducts: [],
    vendorInvoice: [],
    vendorPaymentInvoice: [],
    returnInvoiceList: [],
    invoiceData: [],
    inventoryProducts: [],
};

const slice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },


        // GET CATEGORY
        getInvoiceListSuccess(state, action) {
            state.isLoading = false;
            state.invoiceList = action.payload;
        },

        getInvoiceProductsSuccess(state, action) {
            state.isLoading = false;
            state.invoiceProducts = action.payload;
        },
        getvendorInvoiceSuccess(state, action) {
            state.isLoading = false;
            state.vendorInvoice = action.payload;
        },

        getVendorInvoicePaymentSucess(state, action) {
            state.isLoading = false;
            state.vendorPaymentInvoice = action.payload;
        },

        getReturnInvoiceSuccess(state, action) {
            state.isLoading = false;
            state.returnInvoiceList = action.payload;
        },

        getInvoiceSuccess(state, action) {
            state.isLoading = false;
            state.invoiceData = action.payload;
        },

        getInventoryProductsSuccess(state, action) {
            state.isLoading = false;
            state.inventoryProducts = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getInvoiceList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getInvoiceList`);
            dispatch(slice.actions.getInvoiceListSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getReturnInvoiceList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getReturnedInvoiceList`);
            dispatch(slice.actions.getReturnInvoiceSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getProductsByInvoiceId(invoiceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getInvoiceProductListById/${invoiceId}`);
            dispatch(slice.actions.getInvoiceProductsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getInvoiceListByVendorID(vendorId, fromdate, todate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getInvoiceList/${vendorId}/${fromdate}/${todate}`);
            dispatch(slice.actions.getvendorInvoiceSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ------------------------------------------------------------------------------------------------------------


export function getInvoicePaymentListByVendorID(vendorId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getInvoiceListByVendorId/${vendorId}`);
            dispatch(slice.actions.getVendorInvoicePaymentSucess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -------------------------------------------------------------------------------------------------------------
export function getReturnInvoiceListByVendorID(vendorId, fromdate, todate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getReturnInvoiceList/${vendorId}/${fromdate}/${todate}`);
            dispatch(slice.actions.getReturnInvoiceSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function getViewListByInvoiceId(invoiceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/uploadinventory/getInvoiceById/${invoiceId}`);
            dispatch(slice.actions.getInvoiceSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getInventoryProducts() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/inventories/aggregatedQuantities`);
            dispatch(slice.actions.getInventoryProductsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
