import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    users: [],
    password: [],
    resetpassword: [],
    usersByRoleId: [],
    newUser: [],
    usersByUserId: [],
    userbyrole: [],
    userprofilebyid: [],
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CATEGORIES
        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.users = action.payload;
        },

        // get password
        getPasswordSuccess(state, action) {
            state.isLoading = false;
            state.password = action.payload;
        },

        // get password
        getResetSuccess(state, action) {
            state.isLoading = false;
            state.resetpassword = action.payload;
        },

        getUsersByuserIDSuccess(state, action) {
            state.isLoading = false;
            state.usersByUserId = action.payload;
        },
        getUsersByRoleIDSuccess(state, action) {
            state.isLoading = false;
            state.usersByRoleId = action.payload;
        },
        // GET CATEGORIES
        getNewUsersSuccess(state, action) {
            state.isLoading = false;
            state.newUser = action.payload;
        },
        // GET CATEGORIES
        getUsersByRole(state, action) {
            state.isLoading = false;
            state.userbyrole = action.payload;
        },

        getUserProfileById(state, action) {
            state.isLoading = false;
            state.userprofilebyid = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/users/');
            dispatch(slice.actions.getUsersSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
// ----------------------------------------------------------------------

export function getPasswordById(password, userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/${password}/${userId}`);
            dispatch(slice.actions.getPasswordSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// reset password
export function resetPassword(oldPassword, newPassword, confirmNewPassword, userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/users/resetpassword/${oldPassword}/${newPassword}/${confirmNewPassword}/${userId}`);
            dispatch(slice.actions.getResetSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserByRoleId(roleId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/getUserByRoleId/${roleId}`);
            dispatch(slice.actions.getUsersByRoleIDSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// get new user

export function getNewUsers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/users/get_new_user');
            dispatch(slice.actions.getNewUsersSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getNewUserById(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/get_new_user/${userId}`);
            dispatch(slice.actions.getUsersByuserIDSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getusersByRoleId(roleId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/usersByRoleId/${roleId}`);
            dispatch(slice.actions.getUsersByRole(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// -----------------------------------------------------------------------------------------------------------------------


export function getUserProfileById(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/get_user_profile/${userId}`);
            dispatch(slice.actions.getUserProfileById(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}