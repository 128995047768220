import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  pos: [],
  po: null,
  purchaseOrder:[],
  viewOrder:[],

};

const slice = createSlice({
  name: 'po',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POs
    getPOsSuccess(state, action) {
      state.isLoading = false;
      state.pos = action.payload;
    },

    // GET PO
    getPOSuccess(state, action) {
      state.isLoading = false;
      state.po = action.payload;
    },

  // GET Purchase Order
  getPurchaseOrderSucesss(state, action) {
    state.isLoading = false;
    state.purchaseOrder = action.payload;
  },

  // view purchase order
  viewPurchaseOrcerSucess(state, action) {
    state.isLoading = false;
    state.viewOrder = action.payload;
  },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getPOs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/purchase_order/getAllPO');
      dispatch(slice.actions.getPOsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPObyid(poId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/purchase_order/getPOById/${poId}`);
      dispatch(slice.actions.getPOSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------------

export function getPurchaseOrder() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/purchaseOrder/getPurchaseOrder`);
      dispatch(slice.actions.getPurchaseOrderSucesss(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------------

export function viewPurchaseOrder(poId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/purchaseOrder/viewPurchaseOrder/${poId}`);
      dispatch(slice.actions.viewPurchaseOrcerSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}