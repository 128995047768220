import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  outwardflow: [],
  viewoutwardflow:[],
  getoutwardflow:[]

};

const slice = createSlice({
  name: 'outwardflow',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

      // GET requests
      getOutwardSucess(state, action) {
        state.isLoading = false;
        state.outwardflow = action.payload;
      },

       // View requests
       ViewOutwardSucess(state, action) {
        state.isLoading = false;
        state.viewoutwardflow = action.payload;
      },
// get by idrequests
getOutwardbyIdSucess(state, action) {
  state.isLoading = false;
  state.getoutwardflow = action.payload;
},

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getOutwardflowReq() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/outward_flows/');
        dispatch(slice.actions.getOutwardSucess(response.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
// ----------------------------------------------------------------------

export function ViewOutwardflowdetails(outwardFlowId) {
return async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/outward_flows/getOutwardflowDetailsbyId/${outwardFlowId}`);
    dispatch(slice.actions.ViewOutwardSucess(response.data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
};
}                                                                                                                                                                                                                     



export function getOutwardflowbyId(outwardFlowId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/outward_flows/getoutwardflowbyID/${outwardFlowId}`);
      dispatch(slice.actions.getOutwardbyIdSucess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
  }                                                                                                                                                                                                                     
  