import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    vendors: [],
    vendor: null,
    vendorSchema: null,
    vendorUploads: null,
    vendorExpirySuccess: null,
    vendorConditionSuccess: null,
};

const slice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CATEGORIES
        getVendorsSuccess(state, action) {
            state.isLoading = false;
            state.vendors = action.payload;
        },

        // GET CATEGORY
        getVendorSuccess(state, action) {
            state.isLoading = false;
            state.vendor = action.payload;
        },

        // GET VendorSchema 
        getVendorSchemaSuccess(state, action) {
            state.isLoading = false;
            state.vendorSchema = action.payload;
        },


          // GET Vendor Uploads
          getVendorUploadSuccess(state, action) {
            state.isLoading = false;
            state.vendorUploads = action.payload;
        },

          // GET Vendor Expiry Data
          getVendorExpirySuccess(state, action) {
            state.isLoading = false;
            state.vendorExpirySuccess = action.payload;
        },

        
          // GET Vendor Conditions
          getVendorConditionSuccess(state, action) {
            state.isLoading = false;
            state.vendorConditionSuccess = action.payload;
        },
        
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVendors() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/vendors/');
            dispatch(slice.actions.getVendorsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVendor(vendorId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/vendors/by_vendor_id/${vendorId}`);

            dispatch(slice.actions.getVendorSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}