import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    requests: [],
    request: null,
    checkout: {
        activeStep: 0,
        cart: [],
        total: 0,
        shipping: 0,
        totalItems: 0,
        TotalSQFT: 0,
    },
};

const slice = createSlice({
    name: 'outwardFlowCart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET Requests
        getRequestsSuccess(state, action) {
            state.isLoading = false;
            state.requests = action.payload;
        },

        // GET Request
        getRequestSuccess(state, action) {
            state.isLoading = false;
            state.request = action.payload;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;
            const totalItems = sum(cart.map((product) => product.quantity));
            const TotalSQFT = sum(cart.map((product) => (product.sqft * product.quantity)));
            state.checkout.cart = cart;
            state.checkout.totalItems = totalItems;
            state.checkout.TotalSQFT = TotalSQFT;
        },

        addToCart(state, action) {
            const newProduct = action.payload;
            const isEmptyCart = !state.checkout.cart.length;

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, newProduct];
            } else {
                state.checkout.cart = state.checkout.cart.map((product) => {
                    const isExisted = product.id === newProduct.id;

                    if (isExisted) {
                        return {
                            ...product,
                            quantity: product.quantity + 1,
                        };
                    }

                    return product;
                });
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id');
            state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload);
            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.totalItems = 0;
            state.checkout.total = 0;
            state.checkout.shipping = 0;
            state.checkout.totalItems = 0;
            state.checkout.TotalSQFT = 0;
        },

        backStep(state) {
            state.checkout.activeStep -= 1;
        },

        nextStep(state) {
            state.checkout.activeStep += 1;
        },

        gotoStep(state, action) {
            const step = action.payload;
            state.checkout.activeStep = step;
        },

        increaseQuantity(state, action) {
            const productId = action.payload;

            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        addLengthType(state, action) {
            const data = action.payload;
            const productId = data.productId;
            const area = Number(data.area);
            const sqft = Number(data.sqft);
            const perimeter = Number(data.perimeter);
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        area,
                        sqft,
                        perimeter,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        addLength(state, action) {
            const data = action.payload;
            const productId = data.productId;
            const sqftData = Number(data.sqft);
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        sqft: sqftData,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },
    },
});


// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addToCart,
    resetCart,
    gotoStep,
    backStep,
    nextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    addLengthType,
    addLength,
} = slice.actions;