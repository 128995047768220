
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    invoices: [],
    invoiceDate: [],
    allInvoices:[],
    viewInvoice:[], 
    invoiceById:[],  
};

const slice = createSlice({
    name: 'invoiceReducer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    // GET CATEGORY
    getInvoiceSuccess(state, action) {
        state.isLoading = false;
        state.invoices = action.payload;
    },
    
    getInvoiceDateSuccess(state, action) {
        state.isLoading = false;
        state.invoiceDate = action.payload;
    },

    getInvoicManagementSuccess(state, action) {
        state.isLoading = false;
        state.allInvoices = action.payload;
    },
    viewInvoiceManagementSuccess(state, action) {
        state.isLoading = false;
        state.viewInvoice = action.payload;
    },
    
    getInvoiceIdSuccess(state, action) {
        state.isLoading = false;
        state.invoiceById = action.payload;
    },
    
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getInvoiceList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices/invoices-new`);
            dispatch(slice.actions.getInvoiceSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoiceListBasedOnDueDate(dueDate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoices/individual_invoices/${dueDate}`);
            dispatch(slice.actions.getInvoiceDateSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllInvoices() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoiceManagement/getInvoices`);
            dispatch(slice.actions.getInvoicManagementSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function ViewAllInvoices(invoiceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoiceManagement/viewInvoiceManagement/${invoiceId}`);
            dispatch(slice.actions.viewInvoiceManagementSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoiceById(invoiceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/invoiceManagement/getOneInvoice/${invoiceId}`);
            dispatch(slice.actions.getInvoiceIdSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}