import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  predefinedlength: [],

};

const slice = createSlice({
  name: 'predefinedlength',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
// GET Lengths
getLengthsSuccess(state, action) {
    state.isLoading = false;
    state.predefinedlength = action.payload;
  },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getLengths() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/lengths/getLengths');
        dispatch(slice.actions.getLengthsSuccess(response.data));
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  


// ----------------------------------------------------------------------
