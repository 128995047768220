import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import sectionsReducer from './slices/sections';
import vendorsReducer from './slices/vendors';
import requestsReducer from './slices/requests';
import proformaRequestReducer from './slices/proformaRequest';
import invoiceReducer from './slices/invoice';
import userReducer from './slices/user'
import rolesReducer from './slices/roles';
import lengthsReducer from './slices/lengths';
import skuReducer from './slices/sku'
import manufacturersReducer from './slices/manufacturers';
import proformaInvoiceReducer from './slices/proformaInvoice';
import predefinedlengthReducer from './slices/predefinedlength';
import outwardflowReducer from './slices/outwardFlow';
import inwardflowReducer from './slices/inwardFlow';
import inventoryReducer from './slices/inventory';
import purchaseOrderReducer from './slices/purchase_order';
import outwardFlowCartReducer from './slices/outwardFlowCart';
import ModulePermissionsReducer from "./slices/ModulePemissions";
import inwardFlowAddReducer from './slices/inwardFlowAdd';
import uploadInventoryReducer from './slices/uploadinventory';
import userModuleReducer from './slices/userModule';
import dashbaordReducer from './slices/dashbaord';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const requestsConfig = {
    key: 'requests',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const proformaInvoiceConfig = {
    key: 'proformaInvoice',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};
const outwardFlowCartConfig = {
    key: 'requests',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};
const inwardFlowAddConfig = {
    key: 'inwardFlowAdd',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const uploadInventoryPersistConfig = {
    key: 'uploadInvoice',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['checkout'],
};

const rootReducer = combineReducers({
    sections: sectionsReducer,
    vendors: vendorsReducer,
    requests: persistReducer(requestsConfig, requestsReducer),
    proformaRequest:proformaRequestReducer,
    invoice: invoiceReducer,
    user: userReducer,
    roles: rolesReducer,
    lengths:lengthsReducer,
    skus:skuReducer,
    manufacturers:manufacturersReducer,
    proformaInvoice : persistReducer(proformaInvoiceConfig, proformaInvoiceReducer),
    predefinedlength: predefinedlengthReducer,
    outwardflow:outwardflowReducer,
    inwardflow:inwardflowReducer,
    inventory: inventoryReducer,
    purchaseOrder: purchaseOrderReducer,
    outwardFlowCart : persistReducer(outwardFlowCartConfig, outwardFlowCartReducer),
    modules: ModulePermissionsReducer,
    inwardFlowAdd : persistReducer(inwardFlowAddConfig, inwardFlowAddReducer),
    uploadInventory: persistReducer(uploadInventoryPersistConfig, uploadInventoryReducer),
    userModule: userModuleReducer,
    dashbaord:dashbaordReducer,

});

export { rootPersistConfig, rootReducer };