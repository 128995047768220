import Icons from "icons/sidebar";
import duotone from 'icons/duotone';

const index = [  
  {
    title: "Dashboards",
    Icon: Icons.DashboardIcon,
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
      }
    ]
  },
  {
    title: "Section Management",
    Icon: duotone.Pages,
    children: [
      {
        name: 'Sections List',
        path: '/dashboard/section/section-list',
      }
    ]
  },
  {
    title: "Predefined Length Management",
    Icon: duotone.DiagramProject,
    children: [
      {
        name: "Predefined Length List",
        path: '/dashboard/predefinedlength/predefinedlength-list',
      }
    ]
  },
  {
    title: "Inventories",
    Icon: duotone.LayerGroup,
    children: [
      {
        name: "Inventories",
        path: '/dashboard/inventories/inventorieslist',
      }
    ]
  },
  {
    title: "PO Management",
    Icon: duotone.ArrowLeftToLine,
    children: [
      {
        name: 'PO List',
        path: '/dashboard/request/request-list',
      }
    ]
  },
  {
    title: "Proforma Invoice Management",
    Icon: duotone.Invoice,
    children: [
      {
        name: 'Proforma Invoice List',
        path: '/dashboard/proformaInvoice/proforma-list',
      }
    ]
  },
  {
    title: "Invoice Management",
    Icon: duotone.Pages,
    children: [
      {
        name: "Invoice List",
        path: '/dashboard/invoiceManagementList/invoice-list',
      }
    ]
  },
  {
    title: "Outward Flow Management",
    Icon: duotone.Login,
    children: [
      {
        name: 'Outward Flow List',
        path: '/dashboard/outwardflow/outwardflow-list',
      }
    ]
  },
  {
    title: "Inward Flow Management",
    Icon: duotone.Logout,
    children: [
      {
        name: 'Inward Flow List',
        path: '/dashboard/inwardflow/inwardflow-list',
      }
    ]
  },
  {
    title: "Vendor Management",
    Icon: duotone.UserProfile,
    children: [
      {
        name: 'Vendor List',
        path: '/dashboard/vendor/vendor-list',
      }
    ]
  },
  {
    title: "Users Management",
    Icon: duotone.UserList,
    children: [
      {
        name: 'Users List',
        path: '/dashboard/users/userlist',
      }
    ]
  },
  {
    title: "Roles Management",
    Icon: duotone.UserList,
    children: [
      {
        name: 'Roles List',
        path: '/dashboard/roles/rolelist',
      }
    ]
  },
];

export default index;