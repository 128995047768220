import sum from 'lodash/sum';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  checkout: {
    activeStep: 0,
    batchDetails: [],
    manufacturerId: '',
    proformaId:'',
    invoiceNumber: '',
    invoiceDate: '',
    PKGS: '',
    PCS: '',
    MaterialValue: '',
    PackingCharges: '',
    FreightCharges: '',
    GodownCharges: '',
    INSCharges: '',
    TaxableValue: '',
    CGST: '',
    SGST: '',
    GST:'',
    totalInvoiceValue: '',
    created_by: 0,
  },
};

const slice = createSlice({
  name: 'uploadInventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUpload(state, action) {
      const batchDetails = action.payload;
    },

    // PO CHECKOUT

    addInventory(state, action) {
      const invetoryBody = action.payload;
      state.checkout.manufacturerId = invetoryBody.manufacturerId;
      state.checkout.proformaId = invetoryBody.proformaId;
      state.checkout.invoiceNumber = invetoryBody.invoiceNumber;
      state.checkout.invoiceDate = invetoryBody.invoiceDate;
      state.checkout.PKGS = invetoryBody.PKGS;
      state.checkout.PCS = invetoryBody.PCS;
      state.checkout.MaterialValue = invetoryBody.MaterialValue;
      state.checkout.PackingCharges = invetoryBody.PackingCharges;
      state.checkout.FreightCharges = invetoryBody.FreightCharges;
      state.checkout.GodownCharges= invetoryBody.GodownCharges;
      state.checkout.INSCharges= invetoryBody.INSCharges;
      state.checkout.TaxableValue= invetoryBody.TaxableValue;
      state.checkout.CGST= invetoryBody.CGST;
      state.checkout.SGST= invetoryBody.SGST;
      state.checkout.GST= invetoryBody.GST;
      state.checkout.totalInvoiceValue= invetoryBody.totalInvoiceValue;
      state.checkout.created_by= invetoryBody.created_by;
    },

    batchInventory(state, action) {
      const getData = action.payload;
      state.checkout.batchDetails = getData?.batchDetails || [];
    },

    addSkuToBatchDetails(state, action) {
      const skuData = action.payload;
      state.checkout.batchDetails = state.checkout.batchDetails.map((product) => {
        const isExisted = skuData.section_id === product.section_id;

        if (isExisted) {
          return {
            ...product,
            section_id: skuData.section_id,
            length: skuData.length,
          };
        }

        return product;
      });
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },
    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.batchDetails.filter((product) => product.id !== action.payload);
      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.batchDetails = [];
      state.checkout.manufacturerId = '';
      state.checkout.proformaId = '';
      state.checkout.invoiceNumber = '';
      state.checkout.invoiceDate = null;
      state.checkout.PKGS = '';
      state.checkout.PCS = '';
      state.checkout.MaterialValue = '';
      state.checkout.PackingCharges = '';
      state.checkout.FreightCharges = '';
      state.checkout.GodownCharges = '';
      state.checkout.INSCharges = '';
      state.checkout.TaxableValue = '';
      state.checkout.CGST = '';
      state.checkout.SGST = '';
      state.checkout.GST = '';
      state.checkout.totalInvoiceValue ='';
      state.checkout.created_by = 0;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;
      const totalPackages = sum(cart.map((product) => Number(product.PKGS)));
      const totalPcs = sum(cart.map((product) => Number(product.PCS)));
      const totalMaterialValue = sum(cart.map((product) => Number(product.MaterialValue)));
      const totalPackingCharges = sum(cart.map((product) => Number(product.PackingCharges)));
      const totalFreightCharges = sum(cart.map((product) => Number(product.FreightCharges)));
      const totalGodownCharges = sum(cart.map((product) => Number(product.GodownCharges)));
      const totalINSCharges = sum(cart.map((product) => Number(product.INSCharges)));
      const totalTaxableValue = sum(cart.map((product) => Number(product.TaxableValue)));
      const totalCGST = sum(cart.map((product) => Number(product.CGST)));
      const totalSGST = sum(cart.map((product) => Number(product.SGST)));
      const totalGST = sum(cart.map((product) => Number(product.IGST)));
      const totalInvoiceValue = Number(totalTaxableValue) + Number(totalGST);
      state.checkout.batchDetails = cart;
      state.checkout.PKGS = totalPackages;
      state.checkout.PCS = totalPcs;
      state.checkout.MaterialValue = totalMaterialValue;
      state.checkout.PackingCharges = totalPackingCharges;
      state.checkout.FreightCharges = totalFreightCharges;
      state.checkout.GodownCharges = totalGodownCharges;
      state.checkout.INSCharges = totalINSCharges;
      state.checkout.TaxableValue = totalTaxableValue;
      state.checkout.CGST = totalCGST;
      state.checkout.SGST = totalSGST;
      state.checkout.GST = totalGST;
      state.checkout.totalInvoiceValue = totalInvoiceValue;
      state.checkout.totalCalculatedInvoiceValue = totalInvoiceValue;
    },
  },
});



// Reducer
export default slice.reducer;

// Actions
export const {
  nextStep,
  getUpload,
  backStep,
  gotoStep,
  deleteCart,
  resetCart,
  getCart,
  addInventory,
  batchInventory,
  addSkuToBatchDetails,
} = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
