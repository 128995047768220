import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v2/LayoutV2";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import AuthGuard from "auth/AuthGuard";
import GuestGuard from "auth/GuestGuard";

const Loadable = Component => props => {
  return <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>;
}; // dashboards


const CRM = Loadable(lazy(() => import("./pages/dashboards/crm")));
const Sales = Loadable(lazy(() => import("./pages/dashboards/sales")));
const SalesV2 = Loadable(lazy(() => import("./pages/dashboards/sales-v2")));
const SaaS = Loadable(lazy(() => import("./pages/dashboards/saas")));
const ProjectManagement = Loadable(lazy(() => import("./pages/dashboards/project-management")));
const ProjectManagementV2 = Loadable(lazy(() => import("./pages/dashboards/project-management-v2")));
const JobManagement = Loadable(lazy(() => import("./pages/dashboards/job-management")));
const LearningManagement = Loadable(lazy(() => import("./pages/dashboards/learning-management"))); // account

const Account = Loadable(lazy(() => import("./pages/accounts/account")));
const AccountV2 = Loadable(lazy(() => import("./pages/accounts/account-v2"))); // user and contact

const AddUser = Loadable(lazy(() => import("./pages/user-list/add-new-user")));
const UserList = Loadable(lazy(() => import("./pages/user-list/user-list-view")));
const UserListV2 = Loadable(lazy(() => import("./pages/user-list/user-list-view-v2")));
const UserGrid = Loadable(lazy(() => import("./pages/user-list/user-grid-view")));
const UserGridV2 = Loadable(lazy(() => import("./pages/user-list/user-grid-view-v2")));
const ContactList = Loadable(lazy(() => import("./pages/contact-list/contact-list-view")));
const ContactGrid = Loadable(lazy(() => import("./pages/contact-list/contact-grid-view"))); // profile

const Profile = Loadable(lazy(() => import("./page-sections/profiles/profile/Layout")));
const ProfileV2 = Loadable(lazy(() => import("./pages/profiles/profile-v2"))); // profile

const DataTableV2 = Loadable(lazy(() => import("./pages/data-table/data-table-v2"))); // invoice

const InvoiceList = Loadable(lazy(() => import("./pages/invoice/list")));
const InvoiceList2 = Loadable(lazy(() => import("./pages/invoice/invoice-list-v2")));
const CreateInvoice = Loadable(lazy(() => import("./pages/invoice/create")));
const CreateInvoice2 = Loadable(lazy(() => import("./pages/invoice/add-invoice-v2")));
const InvoiceDetails = Loadable(lazy(() => import("./pages/invoice/details")));
const InvoiceDetails2 = Loadable(lazy(() => import("./pages/invoice/invoice-details-v2"))); // eCommerce

const Shop = Loadable(lazy(() => import("./pages/ecommerce/shop")));
const ShopV2 = Loadable(lazy(() => import("./pages/ecommerce/shop-v2")));
const Cart = Loadable(lazy(() => import("./pages/ecommerce/Cart")));
const Payment = Loadable(lazy(() => import("./pages/ecommerce/payment")));
const Checkout = Loadable(lazy(() => import("./pages/ecommerce/checkout")));
const CheckoutV2 = Loadable(lazy(() => import("./pages/ecommerce/checkout-v2")));
const ProductDetails = Loadable(lazy(() => import("./pages/ecommerce/product-details")));
const BillingAddress = Loadable(lazy(() => import("./pages/ecommerce/billing-address")));
const PaymentComplete = Loadable(lazy(() => import("./pages/ecommerce/payment-complete")));
const PaymentCompleteV2 = Loadable(lazy(() => import("./pages/ecommerce/payment-complete-v2"))); // pricing

const Pricing = Loadable(lazy(() => import("./pages/pricing"))); // pricing

const TodoList = Loadable(lazy(() => import("./pages/todo-list"))); // pricing

const Calendar = Loadable(lazy(() => import("./pages/calendar"))); // authentication

const Login = Loadable(lazy(() => import("./pages/authentication/login")));
const LoginV2 = Loadable(lazy(() => import("./pages/authentication/login-v2")));
const Register = Loadable(lazy(() => import("./pages/authentication/register")));
const RegisterV2 = Loadable(lazy(() => import("./pages/authentication/register-v2")));
const NewPassword = Loadable(lazy(() => import("./pages/authentication/new-password")));
const ForgetPassword = Loadable(lazy(() => import("./pages/authentication/forget-password")));
const ForgetPasswordV2 = Loadable(lazy(() => import("./pages/authentication/forget-password-v2")));
const TwoStepVerify = Loadable(lazy(() => import("./pages/authentication/two-step-verification"))); // admin ecommerce

const ProductList = Loadable(lazy(() => import("./pages/admin-ecommerce/product-list")));
const ProductGrid = Loadable(lazy(() => import("./pages/admin-ecommerce/product-grid")));
const CreateProduct = Loadable(lazy(() => import("./pages/admin-ecommerce/create-product")));
const CustomerManagement = Loadable(lazy(() => import("./pages/admin-ecommerce/customer-management")));
const OrderManagement = Loadable(lazy(() => import("./pages/admin-ecommerce/order-management")));
const ProductManagement = Loadable(lazy(() => import("./pages/admin-ecommerce/product-management"))); // chat

const ChatV1 = Loadable(lazy(() => import("./pages/chats/chat-v1")));
const ChatV2 = Loadable(lazy(() => import("./pages/chats/chat-v2"))); // user projects

const ProjectDetails = Loadable(lazy(() => import("./pages/projects/project-details")));
const ProjectV1 = Loadable(lazy(() => import("./pages/projects/project-v1")));
const ProjectV2 = Loadable(lazy(() => import("./pages/projects/project-v2")));
const TeamMember = Loadable(lazy(() => import("./pages/projects/team-member")));
const ProjectV3 = Loadable(lazy(() => import("./pages/projects/project-v3"))); // Landing page

const AboutPage = Loadable(lazy(() => import("./pages/about")));
const ContactPage = Loadable(lazy(() => import("./pages/contact")));
const PricingPage = Loadable(lazy(() => import("./pages/pricing-v2")));
const PrivacyPage = Loadable(lazy(() => import("./pages/privacy"))); // 404/Error page


// Main

// Section Management
const SectionList = Loadable(lazy(() => import("./pages/sectionManagement/SectionList")));
const SectionAdd = Loadable(lazy(() => import('./page-sections/sections/SectionCreatePage')));
const SectionEdit = Loadable(lazy(() => import('./page-sections/sections/Edit')))

// Vendor Management
const VendorList = Loadable(lazy(() => import("./pages/vendorsManagement/VendorList")));
const VendorAdd = Loadable(lazy(() => import('./page-sections/vendors/VendorCreatePage')));
const VendorEdit = Loadable(lazy(() => import('./page-sections/vendors/Edit')))

// Request Management
const RequestList = Loadable(lazy(() => import("./pages/requestManagement/RequestList")));
const RequestAdd = Loadable(lazy(() => import('./page-sections/requests/Add')));
const RequestView = Loadable(lazy(() => import('./pages/requestManagement/RequestManagementViewPage')))

// Proforma Management
const ProformaList = Loadable(lazy(() => import('./pages/proformaInvoice/ProformaList')));
const ProformaAdd = Loadable(lazy(() => import('./page-sections/proforma/Add')));
const ProformaView = Loadable(lazy(() => import('./pages/proformaInvoice/ProformaInvoiceViewPage')));

// Invoice Management
const InvoiceManagementList = Loadable(lazy(() => import('./pages/invoiceManagement/InvoiceList')));
const InvoiceManagementView = Loadable(lazy(() => import('./pages/invoice/View')));
const InvoiceManagementAdd = Loadable(lazy(() => import('./page-sections/invoiceList/Add')))

// User Management
const UserManagementList = Loadable(lazy(() => import('./pages/usersManagement/UserManagementList')));
const UserAdd = Loadable(lazy(() => import('./page-sections/usersList/UsersCreatePage')));
const UserEdit = Loadable(lazy(() => import('./page-sections/usersList/Edit')))

// Roles Management
const RoleManagementList = Loadable(lazy(() => import('./pages/rolesManagement/RoleList')));
const RoleAdd = Loadable(lazy(() => import('./page-sections/roles/RoleCreatePage')));
const RoleEdit = Loadable(lazy(() => import('./page-sections/roles/Edit')));
const RoleModulePermissions = Loadable(lazy(() => import('./pages/rolesManagement/RoleModulePermissionsList')))

// predefinedlength Management
const PredefinedLengthListPage = Loadable(lazy(() => import("./pages/predefinedlength/PredefinedLengthListPage")));
const PredefinedLengthCreatePage = Loadable(lazy(() => import("./pages/predefinedlength/PredefinedLengthCreatePage")));
const PredfinedLengthEdit = Loadable(lazy(() => import('./page-sections/predfinedLength/Edit')))
// outwardflow Management
const OutwardFlowListPage = Loadable(lazy(() => import("./pages/outwardflowmanagement/OutwardFlowListPage")));
const ViewOutwardFlowListPage = Loadable(lazy(() => import('./pages/outwardflowmanagement/OutwardFlowViewPage')));
const AddOutwardRequest = Loadable(lazy(() => import("./pages/outwardflowmanagement/AddOutwardRequest")));

// inwardflow Management
const InwardFlowListPage = Loadable(lazy(() => import("./pages/inwardflowmanagement/InwardFlowListPage")));
const ViewInwardFlowListPage = Loadable(lazy(() => import("./pages/inwardflowmanagement/InwardFlowViewPage")))
const AddInwardRequest = Loadable(lazy(() => import("./pages/inwardflowmanagement/AddInwardRequest")));

// inventories
const Inventories = Loadable(lazy(() => import("./pages/inventories/inventoriesList")));


// change password
const UserAccountPage = Loadable(lazy(() => import('./pages/dashboard/UserAccountPage')));


const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const {
    settings
  } = useSettings(); 

  switch (settings.activeLayout) {
    case "layout1":
      return <LayoutV1 />;

    case "layout2":
      return <LayoutV2 />;

    case "layout3":
      return <LayoutV3 />;

    default:
      return <LayoutV1 />;
  }
};

const routes = () => {
  return [
    {
      path: "/login",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      index: true
    },
    ...authRoutes,
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <ActiveLayout />
        </AuthGuard>
      ),
      children: dashboardRoutes
    },
    {
      path: "*",
      element: <Error />
    }];
};

// const publicRoutes = [
//   {
//     path: "login",
//     element: <Login />
//   },
//   // {
//   //   path: "login-v2",
//   //   element: <LoginV2 />
//   // }, 
//   // {
//   //   path: "register",
//   //   element: <Register />
//   // }, 
//   // {
//   //   path: "register-v2",
//   //   element: <RegisterV2 />
//   // }, 
//   // {
//   //   path: "new-password",
//   //   element: <NewPassword />
//   // }, 
//   // {
//   //   path: "forget-password",
//   //   element: <ForgetPassword />
//   // }, 
//   // {
//   //   path: "two-step-verification",
//   //   element: <TwoStepVerify />
//   // },
// ]

const authRoutes = [{
  path: "/",
  element: <Navigate to="/login" />
},];

const dashboardRoutes = [
  {
    path: "",
    element: <LearningManagement />
  },

  // Section Management
  {
    path: "section/section-list",
    element: <SectionList />
  },

  // Section Management Add

  {
    path: "section/sectionAdd",
    element: <SectionAdd />
  },

  // Section Management Edit
  {
    path: "section/sectionEdit/:sectionId",
    element: <SectionEdit />
  },
  // Vendors Management

  {
    path: "vendor/vendor-list",
    element: <VendorList />
  },

  // Vendors Management Add
  {
    path: "vendor/vendorAdd",
    element: <VendorAdd />
  },

  // Vendor Management Edit

  {
    path: "vendor/vendorEdit/:vendorId",
    element: <VendorEdit />
  },

  // Request Management
  {

    path: "request/request-list",
    element: <RequestList />
  },

  // Request Management Add

  {
    path: "request/requestAdd",
    element: <RequestAdd />
  },

  // Request Management View

  {
    path: "request/requestView/:requestId",
    element: <RequestView />
  },
  // Proforma Invoice
  {
    path: "proformaInvoice/proforma-list",
    element: <ProformaList />
  },

  // proforma Invoice Add

  {
    path: "proformaInvoice/proformaAdd",
    element: <ProformaAdd />
  },

  // proforma invoice  View

  {
    path: "proformaInvoice/proformaView/:proformaId",
    element: <ProformaView />
  },
  // Invoice Management

  {
    path: "invoiceManagementList/invoice-list",
    element: <InvoiceManagementList />
  },

  // Invoice Management View
  {
    path: "invoiceManagementList/viewInvoice/:invoiceId",
    element: <InvoiceManagementView />
  },
  
  // Invoice Management Add
  {
  path:"invoiceManagementList/invoiceAdd",
  element: <InvoiceManagementAdd />
  },
  // User Management
  {
    path: "users/userlist",
    element: <UserManagementList />
  },

  // User Management Add

  {
    path: "users/userAdd",
    element: <UserAdd />
  },
  // User Management Edit

  {
    path: "users/userEdit/:userId",
    element: <UserEdit />
  },
  // Roles Management
  {
    path: "roles/rolelist",
    element: <RoleManagementList />
  },
  // Roles Management Add
  {
    path: "roles/roleAdd",
    element: <RoleAdd />
  },
  // Roles Management Edit

  {
    path: "roles/roleEdit/:roleId",
    element: <RoleEdit />
  },

  // Roles Management Module Permissions

  {
    path: "roles/roleModulePermissions/:roleId",
    element: <RoleModulePermissions />
  },
  // predefined length
  {
    path: "predefinedlength/predefinedlength-list",
    element: <PredefinedLengthListPage />
  },
  {
    path: "predefinedlength/predefinedlengthcreate-page",
    element: <PredefinedLengthCreatePage />
  },
  // predfeined length create page
  {
    path: "predefinedlength/predefinedlengthcreate-page",
    element: <PredefinedLengthCreatePage />
  },

  // predefined length Edit Page
  {
    path: "predefinedlength/predefinedlengthEdit/:lengthId",
    element: <PredfinedLengthEdit />
  },
  // Outward flow
  {
    path: "outwardflow/outwardflow-list",
    element: <OutwardFlowListPage />
  },
  {
    path: "outwardflow/view/:outwardFlowId",
    element: <ViewOutwardFlowListPage />
  },
  {
    path: "outwardflow/outwardflow-page",
    element: <AddOutwardRequest />
  },
  // inward flow
  {
    path: "inwardflow/inwardflow-list",
    element: <InwardFlowListPage />
  },
  {
    path: "inwardflow/inwardflow-page",
    element: <AddInwardRequest />
  },
  // inward flow view

  {
    path: "inwardflow/view/:inwardFlowId",
    element: <ViewInwardFlowListPage />
  },

  // inventories
  {
    path: "inventories/inventorieslist",
    element: <Inventories />
  },
    
// Account Page
{
  path:'account',
  element: <UserAccountPage />
},
{
  path:'profile/:userId',
  element: <Profile />
},
 {
    path: "privacy",
    element: <PrivacyPage />
  }];
export default routes;