import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios from '../../utils/axios'
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userByRoleId: null,
};

const slice = createSlice({
  name: 'userModule',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getUserModuleByUserId(state, action) {
      state.isLoading = false;
      state.userByRoleId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUserModuleByUserId(userId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`userModules/getUserModuleByUserId/${userId}`);
      dispatch(slice.actions.getUserModuleByUserId(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
