import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import SnackbarProvider from './components/snackbar';
import { createCustomTheme } from "./theme";
import routes from "./routes";
import "./i18n";

const App = () => {
  const router = createBrowserRouter(routes());
  const {
    settings
  } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes
  });
  return <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <RTL>
          <CssBaseline />
          <RouterProvider router={router} />
        </RTL>
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>;
};

export default App;